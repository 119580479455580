import React, { useContext } from 'react'
import { LocaleProvider } from './hooks/useLocale'
import { Selector } from './modules'
import { LangProvider } from './providers/LangProvider'
import { IWidgetProps } from './IWidgetProps'
import { ApiRepositoryProvider } from './hooks/useApiRepository'
import { CameraTimeOffsetProvider } from './providers/CameraTimeOffsetProvider'

const WidgetPropsContext = React.createContext<IWidgetProps>(null!)
export function useWidgetProps() {
  return useContext(WidgetPropsContext)
}

const DEFAULT_LIB_API = {
  name: 'lib-api',
  mixins: ['cubejs']
}

export default function Root(widgetProps: IWidgetProps) {
  return (
    <WidgetPropsContext.Provider value={widgetProps}>
      <LangProvider value={widgetProps.props.lang}>
        <LocaleProvider value={widgetProps.props.locale}>
          <ApiRepositoryProvider lib={widgetProps?.props?.lib || DEFAULT_LIB_API}>
            <CameraTimeOffsetProvider timezoneSettings={widgetProps.props.camera?.timezoneSettings}>
              <Selector />  
            </CameraTimeOffsetProvider>
          </ApiRepositoryProvider>
        </LocaleProvider>
      </LangProvider>
    </WidgetPropsContext.Provider>
  )
}
