/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {FC} from 'react';
import {Spinner} from './controls/Spinner';
import {useWidgetProps} from '../Root';

interface Props {
  loading?: boolean | string;
  error?: string;
}

export const StatusInfo: FC<Props> = ({error, loading, children}) => {
  const {props: {height = '100%', width = '100%'} = {}} = useWidgetProps();
  let overlay = null;
  if (loading) {
    overlay = (
      <div css={overlayCss}>
        <Spinner />
        {typeof loading === 'string' ? <div style={{transform: 'translateY(60px)'}} data-cy="archive-player-load-message">{loading}</div> : null}
      </div>
    );
  } else if (error) {
    overlay = <div css={overlayCss} data-cy="archive-player-error-message">{error}</div>;
  }
  return (
    <div css={statusCss} style={{height, width}}>
      {overlay}
      {children}
    </div>
  );
};

// language=SCSS
const statusCss = css`
  & {
    position: relative;
    background: var(--player-video-bg);
  }
`;

// language=SCSS
const overlayCss = css`
  & {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    font-weight: 500;
    color: var(--player-text-color);
  }
`;
